import { createSignal, onCleanup, onMount, type ParentProps } from "solid-js";
import { MediaQuery, useMediaQuery } from "~/use-media-query";
import { run } from "~/utils";

const hasUserConsent = () => {
  if (typeof window === "undefined") return;
  return Boolean(window?.Didomi?.getCurrentUserStatus()?.addtl_consent);
};

// Corrections should match the sizes of the pet grid component minus 200vw
const CORRECTIONS = {
  xs: 462.5,
  md: 70,
  lg: -35,
  xl: -72.5,
};

const fadeValues: Keyframe[] = [{ opacity: 1 }];

const fadeSettings: KeyframeAnimationOptions = {
  duration: 150,
  easing: "ease-out",
  fill: "forwards",
};

const introValues: Keyframe[] = [
  {
    transform: "none",
  },
];

const introSettings: KeyframeAnimationOptions = {
  delay: 500,
  easing: "cubic-bezier(.46,1.24,.52,.99)",
  fill: "forwards",
  duration: 2000,
};

const [hasAnimated, setHasAnimated] = createSignal(false);

export const IntroAnimation = (props: ParentProps) => {
  const isMd = useMediaQuery(MediaQuery.up("md"));
  const isLg = useMediaQuery(MediaQuery.up("lg"));
  const isXl = useMediaQuery(MediaQuery.up("xl"));

  const correction = () => {
    if (isXl()) return CORRECTIONS.xl;
    if (isLg()) return CORRECTIONS.lg;
    if (isMd()) return CORRECTIONS.md;
    return CORRECTIONS.xs;
  };

  const runAnimation = () => {
    if (!animationRef) return;

    run(async () => {
      await animationRef.animate(fadeValues, fadeSettings).finished;
      await animationRef.animate(introValues, { ...introSettings, duration: isLg() ? 2500 : 1800 }).finished;

      setHasAnimated(true);
    });
  };

  let animationRef!: HTMLDivElement;

  const zoom = () => {
    if (isLg()) return 1;
    return 0.8;
  };

  onMount(() => {
    if (typeof window === "undefined") return;

    const shouldAnimate = !hasAnimated() && window.location.pathname === "/";

    if (!shouldAnimate) {
      setHasAnimated(true);
      return;
    }

    animationRef.style.setProperty("--correction", String(correction()));
    animationRef.style.setProperty("--zoom", String(zoom()));

    if (!window.Didomi || hasUserConsent()) {
      runAnimation();
    } else {
      window.didomiEventListeners = window.didomiEventListeners || [];
      window.didomiEventListeners.push({
        event: "notice.hidden",
        listener: function () {
          if (hasAnimated()) return;
          runAnimation();
        },
      });
    }
  });

  onCleanup(() => {
    if (typeof window === "undefined") return;

    window.didomiEventListeners = [];
  });

  const shouldFadeIn = () => {
    if (typeof window === "undefined") return false;
    return hasAnimated() || (window.Didomi && !hasUserConsent());
  };

  return (
    <div
      ref={animationRef}
      class="relative h-dvh w-full will-change-transform"
      style={{
        "--correction": "0",
        opacity: shouldFadeIn() ? 1 : 0,
        transform: `scale(var(--zoom)) translateX(calc(100% + var(--correction) * 0.5vw)) translateY(calc(-0.5vw * var(--correction) - 100vw))`,
      }}
    >
      {props.children}
    </div>
  );
};
